// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBK9oXK6QYQsP9lZh9y5tOuBTW1mqBXMRo",
  authDomain: "imagegen-9226f.firebaseapp.com",
  databaseURL: "https://imagegen-9226f-default-rtdb.firebaseio.com",
  projectId: "imagegen-9226f",
  storageBucket: "imagegen-9226f.appspot.com",
  messagingSenderId: "527749234422",
  appId: "1:527749234422:web:dd144105d6f7757d5e76d7"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const Auth = getAuth(app)
export const Provider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)